<template>
    <v-container>
        <v-layout
            text-xs-center
            wrap
        >
            <template
                v-if="initialized"
            >
                <v-flex>
                    <v-layout justify-center>

                        <v-card
                            class="mx-auto"
                            max-width="344"
                            outlined
                        >
                            <v-card-title class="justify-center">
                                {{ appNm }}
                            </v-card-title>

                            <v-card-text>
                                <div class="text--primary">
                                    파일 명 : <span id="fileName">{{ apkFileNm }}</span><br />
                                    앱 버전 : <span id="appVer">{{ appVer }}</span><br />
                                    업데이트 일자 : <span id="lstChgDate">{{ lstChgDate }}</span><br />
                                </div>
                            </v-card-text>

                            <v-card-text>
                                <div class="desc">
                                    1. 안드로이드 스마트폰에서만 구동됩니다.<br />
                                    (안드로이드 버전 4.4 이상 필요)<br />
                                    <br />
                                    2. 설치가 되지 않으시면 (환경설정-애플리케이션-알수없는소스) 를 허용하여 주세요.<br />
                                    <br />
                                    3. 다운로드 받으신 후 파일탐색기를 이용하여 apk 파일을 실행합니다.<br />
                                    <br />
                                    4. 하단의 다운로드 버튼을 클릭하시면 다운로드가 시작됩니다.<br />
                                    <br />
                                </div>
                            </v-card-text>

                            <v-card-actions class="justify-center">
                                <v-btn
                                    outlined
                                    rounded
                                    text
                                    @click="getApkFile"
                                >
                                    <v-icon
                                        dark
                                        left
                                    >
                                        mdi-package-down
                                    </v-icon>
                                    APK 파일 다운로드
                                </v-btn>
                            </v-card-actions>

                            <template
                                v-if="this.params.appCd === 'AG'"
                            >
                                <v-card-actions class="justify-center">
                                    <v-btn
                                        outlined
                                        rounded
                                        text
                                        @click="getXlsFile('sample1.xls')"
                                    >
                                        <v-icon
                                            dark
                                            left
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                        엑셀 샘플 다운로드 (가공)
                                    </v-btn>
                                </v-card-actions>
                                <v-card-actions class="justify-center">
                                    <v-btn
                                        outlined
                                        rounded
                                        text
                                        @click="getXlsFile('sample2.xls')"
                                    >
                                        <v-icon
                                            dark
                                            left
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                        엑셀 샘플 다운로드 (지중)
                                    </v-btn>
                                </v-card-actions>
                            </template>
                        </v-card>

                    </v-layout>
                </v-flex>
            </template>
            <template
                v-else
            >
                <v-flex
                    class="circular-wrapper"
                >
                    <v-progress-circular
                        :size="64"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-flex>
            </template>
        </v-layout>
    </v-container>
</template>

<style>
.container {
    width: 100%;
    text-align: center;
}
</style>

<script>
export default {
    name: 'Download',

    data: () => ({
        params: [],
        initialized: false,

        appNm: '',
        apkFileNm: '',
        appVer: '',
        lstChgDate: ''
    }),
    created() {
        this.initView()
    },
    methods: {
        initView() {
            // 요청 파라미터 처리
            this.params = this.parseParams(window.location.search.substr(1).split('&'))

            if (typeof this.params.appCd == 'undefined') {
                alert('비정상적인 요청입니다.')
                history.back()
                return false
            }

            // 다운로드 정보 요청
            this.requestDownloadInfo()
        },
        requestDownloadInfo() {
            let config = {
                headers: {
                    'contentType': 'application/json'
                }
            }

            let uri = '/api/app/getList.do?appCd=' + this.params.appCd

            axios.get(uri, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed' && data.total !== '0') {
                    let dataItem = data.message[0]

                    this.appNm = dataItem.appNm
                    this.apkFileNm = dataItem.apkFileNm
                    this.appVer = dataItem.appVer

                    // 최종수정일 형식 변경
                    this.lstChgDate = new Date(dataItem.lstChgDate)
                    this.lstChgDate = this.lstChgDate.customFormat("#YYYY#-#MM#-#DD# #hh#:#mm#:#ss#")

                    this.initialized = true
                } else {
                    alert("다운로드 할 파일이 존재하지 않습니다.")
                    history.back()
                    return false
                }
            })
        },
        parseParams(e) {
            let params = {}

            // 파라미터가 존재하는 경우에만 수행
            if (e !== "") {

                // 파라미터 수 만큼 반복
                for (let i = 0; i < e.length; i++) {
                    let val = e[i].split('=')

                    if (val.length !== 2) {
                        continue
                    }

                    params[val[0]] = decodeURIComponent(val[1].replace(/\+/g, " "))
                }
            }

            return params
        },
        getApkFile() {
            window.location.href = "/apps/download/" + this.apkFileNm
        },
        getXlsFile(fileName) {
            window.location.href = "/static/" + fileName
        }
    }
}

Date.prototype.customFormat = function(formatString){
    var YYYY,YY,MMMM,MMM,MM,M,DDDD,DDD,DD,D,hhhh,hhh,hh,h,mm,m,ss,s,ampm,AMPM,dMod,th;
    YY = ((YYYY=this.getFullYear())+"").slice(-2);
    MM = (M=this.getMonth()+1)<10?('0'+M):M;
    MMM = (MMMM=["January","February","March","April","May","June","July","August","September","October","November","December"][M-1]).substring(0,3);
    DD = (D=this.getDate())<10?('0'+D):D;
    DDD = (DDDD=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][this.getDay()]).substring(0,3);
    th=(D>=10&&D<=20)?'th':((dMod=D%10)==1)?'st':(dMod==2)?'nd':(dMod==3)?'rd':'th';
    formatString = formatString.replace("#YYYY#",YYYY).replace("#YY#",YY).replace("#MMMM#",MMMM).replace("#MMM#",MMM).replace("#MM#",MM).replace("#M#",M).replace("#DDDD#",DDDD).replace("#DDD#",DDD).replace("#DD#",DD).replace("#D#",D).replace("#th#",th);
    h=(hhh=this.getHours());
    if (h==0) h=24;
    if (h>12) h-=12;
    hh = h<10?('0'+h):h;
    hhhh = hhh<10?('0'+hhh):hhh;
    AMPM=(ampm=hhh<12?'am':'pm').toUpperCase();
    mm=(m=this.getMinutes())<10?('0'+m):m;
    ss=(s=this.getSeconds())<10?('0'+s):s;
    return formatString.replace("#hhhh#",hhhh).replace("#hhh#",hhh).replace("#hh#",hh).replace("#h#",h).replace("#mm#",mm).replace("#m#",m).replace("#ss#",ss).replace("#s#",s).replace("#ampm#",ampm).replace("#AMPM#",AMPM);
};
</script>