<template>
    <v-container>
        <v-layout
            text-xs-center
            wrap
        >
            <template
                v-if="initialized"
            >
                <v-app-bar color="dark" dark fixed app>
                    <v-toolbar-title>
                        사용자 관리
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <span @click="showAdmInfo">{{ admInfo.admNm }}</span> 님 환영합니다.

                        <v-btn
                            class="ma-2"
                            @click="logout"
                        >
                            <v-icon
                                dark
                                left
                            >
                                mdi-logout
                            </v-icon>
                            로그아웃
                        </v-btn>
                    </v-toolbar-title>
                </v-app-bar>

                <v-container fluid fill-height style="padding: 0 !important;">
                    <v-layout justify-center>
                        <div class="container">

                            <v-card>
                                <!--
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="검색"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                -->

                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="search"
                                    :options.sync="options"
                                    :page.sync="page"
                                    :items-per-page="pageSize"
                                    :server-items-length="total"
                                    @page-count="pageCount = $event"
                                    @dblclick:row="dblClickedRow"
                                    hide-default-footer
                                >

                                    <template v-slot:footer>
                                        <v-toolbar
                                            flat
                                        >
                                            <v-spacer></v-spacer>
                                            <v-dialog
                                                v-model="showEditDialog"
                                                max-width="500px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        color="primary"
                                                        dark
                                                        class="mb-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        추가
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="text-h5">{{ formTitle() }}</span>
                                                    </v-card-title>

                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row>
                                                                <v-text-field
                                                                    v-model="editedItem.usrId"
                                                                    label="전화번호(사용자 ID)"
                                                                ></v-text-field>
                                                            </v-row>
                                                            <v-row>
                                                                <v-text-field
                                                                    v-model="editedItem.usrNm"
                                                                    label="사용자명"
                                                                ></v-text-field>
                                                            </v-row>
                                                            <v-row>
                                                                <!--
                                                                <v-text-field
                                                                    v-model="editedItem.licStat"
                                                                    label="사용여부"
                                                                ></v-text-field>
                                                                -->
                                                                <v-switch
                                                                    v-model="licSwitch"
                                                                    color="primary"
                                                                    label="사용여부"
                                                                    @click="setLicStat"
                                                                ></v-switch>
                                                            </v-row>
                                                            <v-row>
                                                                <v-text-field
                                                                    v-model="editedItem.licMemo"
                                                                    label="메모"
                                                                ></v-text-field>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="close"
                                                        >
                                                            취소
                                                        </v-btn>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="updateItem"
                                                        >
                                                            저장
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-dialog v-model="showDeleteDialog" max-width="500px">
                                                <v-card>
                                                    <v-card-title class="text-h5">해당 사용자를 삭제하시겠습니까?</v-card-title>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="blue darken-1" text @click="close">취소</v-btn>
                                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">확인</v-btn>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.licStat="{ item }">
                                        <v-chip
                                            color="green"
                                            dark
                                            v-if="item.licStat === '1'">
                                            사용
                                        </v-chip>
                                        <v-chip
                                            color="red"
                                            dark
                                            v-else>
                                            정지
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="editItem(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            @click="deleteItem(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>

                                </v-data-table>
                                <div class="text-center pt-2">
                                    <v-pagination
                                        v-model="page"
                                        :length="pageCount"
                                    ></v-pagination>
                                </div>

                                <v-card-text>
                                    <span>
                                        <a target="_blank" :href="'/Download?appCd=' + admInfo.appCd">APP 다운로드 페이지</a>
                                    </span>
                                </v-card-text>

                            </v-card>

                        </div>
                    </v-layout>
                </v-container>
            </template>
            <template
                v-else
            >
                <v-flex
                    class="circular-wrapper"
                >
                    <v-progress-circular
                        :size="64"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-flex>
            </template>
        </v-layout>
    </v-container>
</template>

<style>
.container {
    width: 100%;
    text-align: center;
}
</style>

<script>
export default {
    name: 'License',

    data: () => ({
        key: '',
        initialized: false,

        showEditDialog: false,
        showDeleteDialog: false,

        licSwitch: false,

        admInfo: [],

        search: '',
        headers: [
            {
                text: '사업소명',
                align: 'center',
                sortable: false,
                value: 'ofcNm',
            },
            {
                text: '앱 명칭',
                align: 'center',
                sortable: false,
                value: 'appNm',
            },
            {
                text: '전화번호',
                align: 'center',
                sortable: false,
                value: 'usrId',
            },
            {
                text: '사용자명',
                align: 'center',
                sortable: false,
                value: 'usrNm',
            },
            {
                text: '상태',
                align: 'center',
                sortable: false,
                value: 'licStat',
            },
            {
                text: '최종접속일',
                align: 'center',
                sortable: false,
                value: 'licReqDate',
            },
            {
                text: '메모',
                align: 'center',
                sortable: false,
                value: 'licMemo',
            },
            {
                text: '',
                align: 'center',
                sortable: false,
                value: 'actions'
            }
        ],
        options: {},
        items: [],

        editedItem: {},
        editedIndex: -1,

        page: 1,
        pageSize: 20,
        pageCount: 0,
        total: 0
    }),
    created() {
        this.init()
    },
    watch: {
        showEditDialog (val) {
            val || this.close()
        },
        showDeleteDialog (val) {
            val || this.close()
        },
    },
    beforeMount() {
        /*
        window.addEventListener("beforeunload", this.preventNav)
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        })
        */
    },
    methods: {
        init: function() {
            this.key = this.$store.getters.getKey

            if (this.key == null || this.key.length < 1) {
                alert('로그인이 필요합니다.')

                this.$router.push('/')
            } else {
                this.getAuthInfo()

            }
        },
        getAuthInfo() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/auth/info'

            axios.get(uri, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed') {
                    this.admInfo = data.message

                    this.initialized = true

                    this.getLicenseList()
                } else {
                    alert('로그인이 필요합니다.')

                    this.$router.push('/')
                }

            })
        },
        showAdmInfo() {
            var url = "/AdminInfo"
            var title = "관리자 정보 수정"

            popupWindow(url, title, 480, 360)
        },
        getLicenseList() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/api/lic/getList.do?ofcCd=' + this.admInfo.ofcCd + '&page=' + this.page + '&pageSize=' + this.pageSize

            axios.get(uri, config).then((resp) => {
                let data = resp.data
                this.items = data.message
                this.total = parseInt(data.total)
            })
        },
        setLicStat() {
            this.editedItem.licStat = this.licSwitch === true ? '1' : '0'
        },
        dblClickedRow(event, item) {
            this.editItem(item.item)
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.showEditDialog = true

            this.licSwitch = this.editedItem.licStat === '1'
        },
        deleteItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.showDeleteDialog = true
        },
        close() {
            this.showEditDialog = false
            this.showDeleteDialog = false
            this.$nextTick(() => {
                this.licSwitch = false
                this.editedItem = {}
                this.editedIndex = -1
            })
        },
        updateItem() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/api/lic/update.do'

            let data = this.editedItem

            data.ofcCd = this.admInfo.ofcCd
            data.appCd = this.admInfo.appCd

            axios.post(uri, data, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed') {
                    alert('저장하였습니다.')
                } else {
                    alert('저장에 실패하였습니다.\n' + data.message)
                }

                this.close()

                this.getLicenseList()
            })
        },
        deleteItemConfirm() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/api/lic/delete.do'

            let data = this.editedItem

            data.ofcCd = this.admInfo.ofcCd
            data.appCd = this.admInfo.appCd

            axios.post(uri, data, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed') {
                    alert('삭제하였습니다.')
                } else {
                    alert('삭제에 실패하였습니다.')
                }

                this.close()

                this.getLicenseList()
            })
        },
        formTitle () {
            return '사용자 ' + (this.editedIndex === -1 ? '추가' : '수정')
        },
        preventNav(event) {
            event.preventDefault()
            event.returnValue = ""
        },
        logout() {
            sessionStorage.clear()
            this.$router.push('/')
        }
    }
}

// popup window
function popupWindow(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=no, resizable=0, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}
</script>