<template>
    <v-container>
        <v-layout
            text-xs-center
            wrap
        >
            <template
                v-if="!isValidUser"
            >
                <v-flex>
                    <v-layout justify-center>
                        <div class="wrapper">
                            <h2 class="headline font-weight-bold mb-3">본인 확인을 위해 암호를 입력해주세요.</h2>

                            <v-flex>
                                <v-text-field
                                    v-model="usrPw"
                                    :rules="pwRules"
                                    label="비밀번호"
                                    type="password"
                                    v-on:keyup.enter="confirm"
                                    required
                                ></v-text-field>
                            </v-flex>

                            <v-btn
                                class="ma-2"
                                color="primary"
                                @click="confirm"
                            >
                                <v-icon
                                    dark
                                    left
                                >
                                    mdi-magnify
                                </v-icon>
                                확인
                            </v-btn>

                        </div>
                    </v-layout>
                </v-flex>
            </template>
            <template
                v-else
            >

                <v-card
                    style="width: 100%;"
                >
                    <v-card-title>
                        <span class="text-h5">관리자 정보 수정</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-text-field
                                    v-model="admInfo.admId"
                                    label="ID"
                                    disabled
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="admInfo.admPw"
                                    :rules="pwRules"
                                    label="비밀번호"
                                    type="password"
                                ></v-text-field>
                            </v-row>
                            <!--
                            <v-row>
                                <v-text-field
                                    v-model="admInfo.admNm"
                                    label="사용자명"
                                ></v-text-field>
                            </v-row>
                            -->
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                        >
                            취소
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="updateInfo"
                        >
                            저장
                        </v-btn>
                    </v-card-actions>
                </v-card>




            </template>
        </v-layout>
    </v-container>
</template>

<style>
.wrapper {
    width: 300px;
    text-align: center;
}
</style>

<script>
export default {
    name: 'Login',

    data: () => ({
        key: '',
        isValidUser: false,
        usrPw: '',
        pwRules: [
            v => !!v || '암호를 입력해주세요',
            //v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        admInfo: []
    }),
    created() {
        this.init()
    },
    methods: {
        init: function() {
            this.key = this.$store.getters.getKey

            if (this.key == null || this.key.length < 1) {
                alert('로그인이 필요합니다.')

                this.$router.push('/')
            } else {
                this.getAuthInfo()
            }
        },
        getAuthInfo() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/auth/info'

            axios.get(uri, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed') {
                    this.admInfo = data.message
                } else {
                    alert('로그인이 필요합니다.')

                    this.$router.push('/')
                }

            })
        },
        confirm: function() {
            if (this.admInfo.admPw === this.usrPw) {
                this.isValidUser = true
            } else {
                alert('비밀번호가 일치하지 않습니다.')
            }
        },
        close: function() {
            window.close()
        },
        updateInfo: function() {
            let config = {
                headers: {
                    'X-AUTH-TOKEN': this.key
                }
            }

            let uri = '/auth/update'

            let data = this.admInfo

            data.ofcCd = this.admInfo.ofcCd
            data.appCd = this.admInfo.appCd

            axios.post(uri, data, config).then((resp) => {
                let data = resp.data

                if (data.result === 'succeed') {
                    alert('저장하였습니다.')
                } else {
                    alert('저장에 실패하였습니다.\n' + data.message)
                }

                this.close()
            })
        }
    }
}
</script>