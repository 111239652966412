<template>
    <v-container>
        <v-layout
            text-xs-center
            wrap
        >
            <v-flex>
                <v-layout justify-center>
                    <div class="wrapper">
                        <h2 class="headline font-weight-bold mb-3">로그인이 필요합니다.</h2>

                        <v-form
                            v-model="valid"
                            ref="frmLogin"
                            lazy-validation
                        >
                            <v-flex>
                                <v-text-field
                                    v-model="usrId"
                                    :rules="idRules"
                                    label="아이디"
                                    required
                                ></v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field
                                    v-model="usrPw"
                                    :rules="pwRules"
                                    label="비밀번호"
                                    type="password"
                                    v-on:keyup.enter="jssubmit"
                                    required
                                ></v-text-field>
                            </v-flex>

                            <v-btn
                                class="ma-2"
                                color="primary"
                                @click="jssubmit"
                            >
                                <v-icon
                                    dark
                                    left
                                >
                                    mdi-magnify
                                </v-icon>
                                로그인
                            </v-btn>
                        </v-form>
                    </div>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<style>
.wrapper {
    width: 300px;
    text-align: center;
}
</style>

<script>
export default {
    name: 'Login',

    data: () => ({
        valid: false,
        usrId: '',
        usrPw: '',
        showAlert: false,
        errMsg: '',
        idRules: [
            v => !!v || '아이디를 입력해주세요',
            //v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        pwRules: [
            v => !!v || '암호를 입력해주세요',
            //v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
    }),
    methods: {
        jssubmit: function() {
            if (this.$refs.frmLogin.validate()) {
                let uri = '/auth/signin?id=' + this.usrId + '&pw=' + this.usrPw

                axios.post(uri).then((resp) => {
                    let data = resp.data

                    if (data.result == 'succeed') {
                        let key = data.message

                        //this.$store.state.key = key
                        this.$store.commit('setKey', key)

                        this.$router.push('/License')
                    } else {
                        alert('아이디 또는 비밀번호를 확인해주세요')
                    }
                })
            }
        }
    }
}
</script>